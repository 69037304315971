/*
 * 1) For each section, please keep styles sorted in alphabetical order! 
 * 2) Don't be sparing (zgarcit) w/ comments. Some times, some lines of CSS may be the result
 * of hours of work, trial and error, etc. 
 */

/*********************************************************************************************************************
 * SECTION: overrides of styles coming from third party libs
 *********************************************************************************************************************/

 .ant-drawer-content {
  background-color: var(--background);
 }

.leaflet-edit-marker-selected { /* copied from leaflet.draw; added widht and height; otherwise problems with edit marker vertex */
	background-color: rgba(254, 87, 161, 0.1);
  border: 4px dashed rgba(254, 87, 161, 0.6);
  width: 3em !important;
  height: 3em !important;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	box-sizing: content-box;
}

/*********************************************************************************************************************
 * SECTION: very general styles, not particular to a certain component
 *********************************************************************************************************************/

 :root { /* same as the ones used by less (See site.variables.less */
  --blue: #3A527B;
  --orange: #ECB22D;
  --green: #2FB67E;
  --red: #CE4040;
  --background: rgba(248, 247, 252, 1);
  --textColor: var(--blue);
}

.MessageExt {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    margin-bottom: 0px !important;
    background-color: white !important;
    box-shadow: 0px 1px 2px 0 rgb(58 82 123 / 10%) !important; /* copied from ui.segment */
    border-radius: 0.42857143rem !important; /* copied from ui.segment */
    border: 1px solid rgba(58, 82, 123, 0.1) !important; /* copied from ui.segment */
}

.MessageExt i {
    color: rgba(0,0,0,.6); /* from .ui.label */
    font-weight: normal;
}

.MessageExt:last-child {
  margin-top: 5px !important;
}

.Message_spacingRightAndLeft {
  margin-right: 10px;
  margin-left: 10px;
}

.Message_spacingRight {
  margin-right: 10px !important;
}

.Message_dateWidth {
  width: 20%;
}

.leaflet-container:first-child {  
  opacity: .99; 
}

/* https://refreshless.com/nouislider/examples/#section-hiding-tooltips */
.noUi-tooltip {
  display: none !important;
}
.noUi-active .noUi-tooltip {
  display: block !important;
}

/* this is used in pages where we didn't find the reason why the height it becomes greater and greater or it's 0 (historcal map, charts) */
.chart_tableHeight {
  height: 100vh;
}

/*********************************************************************************************************************
 * SECTION: styles for components; use the pattern e.g. MyComponent_someStyle. 
 *********************************************************************************************************************/

.AppDrawer .ant-drawer-body, .AppDrawer .ant-drawer-header {
  background-color: var(--blue) !important;
}

.AppDrawer .ant-drawer-header {
  border-bottom-color: var(--blue) !important;
}

.AppDrawer .ant-drawer-title, .AppDrawer .ant-drawer-close {
  color: white !important;
}

 .BlocklyScriptEditor_editorDiv {
    height: 600px;
    width: 100%;
}

.BlocklyScriptEditor_segment {
  max-width: 1200px;
  width: 100%;  
}

.BlocklyScriptEditorBar_div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.BlocklyScriptEditor_equipmentDropdownDiv {
    width: 250px;
    z-index: 1000;
    margin: 0px 5px 0px 5px;
}

.ChartEditorPage_img {
    margin-bottom: 0 !important;
    width: 100px !important;
}

.ChartEditorPage_innerFormContainer {
    max-width: 1200px !important;
}

.ChartPageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.ChartContainer {
  flex-grow: 1;
}

.ChecklistEditorPage_segment {
  width: 100%;
}

.ChecklistButton {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.ChecklistField {
  margin-top: 1rem;
  text-align: center;
}

.ChecklistTextArea {
  padding: 10px;
  margin: 0 10px;
}

.ContainerWithHeader_header, .ContainerWithHeader_header  .ui.menu {
  background-color: var(--blue);  
}

.ContainerWithHeader_header  .ui.menu {  
  align-items: center !important;
}

.ContainerWithHeader_header .ui.menu .item, .ContainerWithHeader_header .ui.menu .item:hover, .ContainerWithHeader_header .ui.menu > a > h4 {
  color: white;
}

.CrudHeader_editorWithFieldsInHeader {
  height: 210px;
}

.OrganizationTreeLeftArea, .OrganizationTreeLeftArea  .ui.segments, .OrganizationTreeLeftArea  .ui.segments .ui.segment {
  background-color: var(--blue);  
  color: white;
}

.OrganizationTreeLeftArea .ui.segments .ui.segment.selectedItem {
  background-color: white !important;
  color: var(--blue);
}

@media only screen and (max-width: 767px) {
  .ui.stackable.grid>.row>.column.ChecklistEditorPageGrid {
    padding: 0.35rem !important;
  }

  .ui.stackable.grid>.row>.ChecklistTextArea {
    margin: 0.35rem !important;
  }
}

.ui.stackable.grid>.row>.column>.ChecklistEditorPageGrid {
  margin: 0;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.BarsChartPageContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.BarsChartsContainer {
  height: calc(100% - 66px);
}

.BarsChartContainer {
  margin-bottom: 1rem;
  height: calc(49% - 1rem);
}

.DistanceAndTimeInTerritories_tooltip {
  padding: 0.3em !important;
}

.ER_EntityEditorPage_header_rightSide {
  position: relative;
  float: right;
  bottom: 216px; 
  right: 15px;
}

.ER_EntityEditorPage_header_rightSide_noImage span { 
  background-color: var(--blue);
}

.ER_EntityEditorPage_header_rightSide_noModel { 
  background-color: var(--blue);
  bottom: 200px;
}

.ER_EntityEditorPage_header_rightSide img, .ER_EntityEditorPage_header_rightSide span {
  height: 170px;
  min-width: 200px;
  margin-bottom: 2px;
  border-radius: 4px;
}

.ER_EntityEditorPage_header_rightSide .ui.label {
  background-color: white;
  margin: 0px;
  text-align: center;
}

.ER_EntityEditorPage_header_rightSide span {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content:center;
  flex-wrap: wrap;
  color: white;
}

.EqResBigInfoArea_updated {
  flex-grow: 1;
  text-align: center;
  color: rgba(0, 0, 0, 0.87); /* copied from semantic ui.basic.label */
}

.EqResBigInfoArea h1 {
  margin-bottom: 0;
}

.EqRes_icon_svg {
  margin-bottom: 7px;
  /* generated from blue color using https://dev.to/jsm91/css-filter-generator-to-convert-from-black-to-target-hex-color-188h, changed invert */
  filter: invert(67%) sepia(87%) saturate(295%) hue-rotate(177deg) brightness(94%) contrast(92%);
}

.EqRes_mapIcon_svg {
  width: 22px !important;
  height: 22px !important;
}

.EqRes_mapIcon_svg_big {
  width: 36px !important;
  height: 36px !important;
}

.EqResBigInfoArea_generalInfo div {
  margin-top: 2px !important;
}

.EqResBigInfoArea .ui.segment, .ui.table thead th {
  padding: 0.5em;
}

.EqResBigInfoArea_available {
  justify-content: space-evenly !important;
  align-items: stretch !important;
  white-space: pre-line !important;
  margin-bottom: 0.5em;
}

.EquipmentUsageReport_usageEntries {
  margin-bottom: 3px !important;
  margin-right: 3px !important;
}

.FlightsAssignmentsPage_buttons {
  position: absolute;
  width: 100%;
  text-align: center;
}

.FlightsAssignmentsPage_card {
  width: 350px !important;
  max-height: 350px !important;
  overflow: auto !important;
  margin-bottom: 0 !important;
}

.FlightsAssignmentsPage_card_border {
  border-width: 1px !important;
  border-style: solid !important;
}

.FlightsAssignmentsPage_label {
  margin-left: 0 !important;
  margin-top: 0.25em !important;
}

.FlightsAssignmentsPage_mission_border {
  border-style: solid !important;
  border-width: 2px !important;
}

.FlightsAssignmentsPage > .ui.segment {
  padding: 0.5em !important;
  margin-bottom: 0.5em;
}

.FlightsAssignmentsPage_parent_dropdown {  
  overflow: visible;
}

.FlightsAssignmentsPage_dropdown {  
  min-width: 200px !important;    
}

.FlightItemRenderer_dateSegment {
  border-left: 3px solid red;
  height: 40px;
  position: absolute;
  top: 0;
}

.GalleryMedia_galleryElement {
  width: 50%;
  height: 50%;
  margin: 0;
  padding-bottom: 25px;
  padding-left: 5px;
  padding-right: 5px;
}

.GalleryMedia_noMediaMessage {
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%,50%);
  color: red
}

.GanttAssignment_copyInputDataTableCell {
  width: 50px;
}

.HistoricalMapChartComponent_chartContainer {
    flex: 1 1 auto;
    /* w/o this (or auto) => an infinite loop that makes the pie grow */
    overflow: hidden;
}

.HistoryCompareTab_segment {
  margin: 0px 10px 0px 10px !important;
}

.MissionsAwaitingForDriversPage_message {
  width: 100%;
  max-width: 700px;
}

.MissionsAwaitingForDriversPage_message_right {
  width: 20%;
}

.MissionsAwaitingForDriversPage_header {
  border-radius: 15px;
  padding: 0.3em;
}

.HistoricalMap_tableHeight {
  height: 500px;
}

.MapContainerLeaflet_cornered-br {
  top: 0.7em !important;
  left: 0.7em !important;
}

.MapContainerLeaflet_cornered-bl {
  top: 0.7em !important;
  left: -0.7em !important;
}

.MapContainerLeaflet_cornered-tr {
	top: -0.7em !important;
  left: 0.7em !important;
}

.MapContainerLeaflet_cornered-tl {
  top: -0.7em !important;
  left: -0.7em !important;
}

.MapContainer_Popup {
  position: relative;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0,0,0,0.2);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  left: -50px;
  min-width: 100px;
}
.MapContainer_Popup:after, .MapContainer_Popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.MapContainer_Popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.MapContainer_Popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.MapContainer_Marker_circle {
  width:3em;
  height:3em;
  border-radius:1em; 
  line-height:3em;
  text-align: center;
}

.MapContainerHeader_segment {
  margin-left: auto;
}

.MapRealTime_topParent {
  position: relative;  
  overflow: hidden;
}
.MapRealTime_topBar {  
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
}

.MapRealTime_topBar_padding {  
  padding-right: 0.5em !important; 
  padding-left: 0.5em !important;
}

.MapRealTime_topBar_right {  
  float: right;
}

.MapRealTime_topRightBar_search .results {
  overflow-y: scroll;
  max-height: 300px
}

.MapRealTime_topBar_label {
  margin: 0 3px 3px 0 !important;
	/* cf. https://stackoverflow.com/a/3797454/306143 to which I arrived cf. https://stackoverflow.com/a/7490286/306143 */
	float: left !important;
	/* w/o this, "word-break" from parent doesn't work */
  white-space: normal !important;
}

.MapRealTime_popup_grid_row_column {  
  padding: 0.5em !important;
  margin: 0 !important;
}

.MapRealTime_popup_grid {  
  margin: 0 !important;
}

.MapRealTime_drawer {
  position: absolute !important;
}
.MapRealTime_drawer > .ant-drawer-mask {  
  opacity: 0 !important;
  -webkit-animation: none !important;
  animation: none !important; 
}

.MapRealTime_filterBar_item_container {
  margin-right: 0.7em;
  padding: 0.4em 0;
  display: inline-block;
  position: relative;
}

.MapRealTime_filterBar_item {
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(220, 220, 220);
  padding: 0.4em;
  background: rgb(247, 247, 247);
  display: inline-block;
}

.MapRealTime_filterBar_item_dot {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
}

.MapRealTime_filterBar_item_dot_topLeft {
  margin-top: -5px;
  margin-left: -5px;
}

.MapRealTime_filterBar_item_dot_topRight {
  margin-top: -5px;
  margin-left: -7px;
}

.MapRealTime_filterBar_item_dot_bottomRight {
  bottom: 1px;
  margin-left: -7px;
}

.MapRealTime_filterBar_item_dot_bottomLeft {
  bottom: 1px;
  margin-left: -5px;
}

.MapTabHistory_leftQuarterWrapper {
    width: 100% ;
}

.MapTabHistory_leftQuarterWrapper .header {
    white-space: nowrap !important;
}

.MapTabHistory_leftQuarterWrapper .inline.fields {
    margin-bottom: 0 !important;
}

.MapTabHistory_leftQuarterWrapper .ant-picker {
    background: none !important;
    margin-left: 15px !important;
}

.MapTabHistory_leftQuarterWrapper .ui.table {
    width: unset !important;
}

.MapTabHistory_leftQuarterWrapper .message {
    padding: 10px 7px !important;
}

.HistoricalMapChartComponent_chartElements {
  display: flex;
  flex-direction: column;
}

.HistoricalMapChartComponent_chartElements div {
  margin-bottom: 4px;
}

.HistoricalMapChartComponent_chartType {
  margin-right: 4px !important;
}

.RealTimeGanttPage_bar {
  margin: 10px 10px 0px 10px !important;
}

.RealTimeGanttPage_content {
  margin: 10px !important;
  height: 100%;
}

.ScanBaggage_container {
  display: flex !important;
  justify-content: center;
}

.ScanBaggage_formDiv {
  display: flex;
  flex-wrap: wrap;
}

.ScanBaggage_formDivElement {
  flex-grow: 1 !important;
}

.ScanBaggage_groupBtn {
  display: flex;
  flex-wrap: wrap;
}

.ScanBaggage_segment {
  max-width: 800px;
  width: 100%;
}

.ScanBaggage_segmentBg {
  display: flex;
  align-items: center;
  justify-content:space-between;
  flex-wrap: wrap;
}

.ScannerModal_input {
  width: 100%;
}

.TaskEditorButton {
  min-width: 7em;
}

.TaskEditorLabel {
  text-align: center;
  font-size: 1rem !important;
  min-height: 1em !important;
  margin: 0 !important;
}

.TaskEditorMargin {
  margin: 0 .25em 0 0 !important;
}

.TaskEditorLabelContainer {
  background: #E8E8E8;
  border-radius: .28571429rem;
  display: inline-block;
}

.TimezonePicker_inline {
  width: 15%;
  display: inline-block;
  color: #000;
}

.VehicleInformationWidget_GridRow {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem;
}

.VehicleInformationWidget_Icon {
  margin-right: 1rem;
}

.VehicleInformationWidet_Value {
  flex: grow;
}

.XopsHomePage_header {
  height: 250px;
  background: var(--blue);
  padding-left: 200px;
  padding-top: 45px;
}

.XopsHomePage_header > .ui.header {
  color: white
}

.XopsHomePage_map {
  height: 420px;
  bottom: 75px;
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.XopsHomePage_dashboardTabWrapper {
  position: relative;
  top: -75px;
}